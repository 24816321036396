<template>
    <v-container fluid>
        <v-row class="d-flex justify-center align-center text-center">
            <v-col cols="12">
                <div class="text-center">
                    <img
                        :src="gift_data.product_img"
                        width="300px"
                        height="fit-content"
                    />
                    <h3 class="mt-3">{{ gift_data.brand_name }}</h3>
                    <h4 class="mt-3 mb-10">{{ gift_data.article_name }}</h4>
                    <v-row>
                        <v-col cols="6">
                            정상판매가
                        </v-col>
                        <v-col cols="6" class="text-decoration-line-through">
                            {{ formatPrice(gift_data.org_price) }}
                        </v-col>
                    </v-row>
                        
                    <h2 class="display-1 font-weight-bold mt-2 mb-8">
                        {{ formatPrice(gift_data.sale_price) }}
                    </h2>

                    <p
                        class="mt-8 detail_info"
                        v-html="gift_data.article_description"
                    ></p>

                    <div class="d-flex">
                        <v-row>
                            <v-col cols="12">
                                <v-btn
                                    class="white--text"
                                    style="min-width: 100px;"
                                    color="grey"
                                    @click="$router.go(-1)"
                                    rounded
                                >
                                취소
                                </v-btn>
                                <v-btn
                                    class="mx-1"
                                    style="min-width: 100px;"
                                    color="primary"
                                    @click="buyAdapter"
                                    rounded
                                >
                                구매
                                </v-btn>
                                <!-- v-if="deposit_type == false" -->

                                <!-- <v-btn
                                    class="mx-1"
                                    v-else
                                    block
                                    depressed
                                    disalbed
                                >
                                    출금신청 중이라 구매하실 수 없습니다.
                                </v-btn> -->
                            </v-col>
                        </v-row>
                    </div>

                    <br />
                </div>
                <!-- </div> -->
            </v-col>
        </v-row>

        <v-divider class="mb-5"></v-divider>

        <!-- <v-row justify="center">
            <v-col cols="12">
                <p class="detail_info">
                    본 상품은 NFT를 통해 발행된 상품으로 중복유통을 막을 수 있습니다.
                </p>
            </v-col>
            <v-col cols="12">
                <v-card full-width tile outlined>
                    <v-card-title>
                    NFT 히스토리
                </v-card-title> -->
                    <!-- <v-divider></v-divider> 
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Event</th>
                                    <th>Who</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="item in gift_data.history"
                                    :key="item.update_time"
                                >
                                    <td>{{ item.event }}</td>
                                    <td>{{ item.owned_by }}</td>
                                    <td>{{ item.update_date }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row> -->

        <!-- </v-container>
            </v-sheet> -->

        <!-- ******************************************************************************* -->
        <!-- fill_dialog : Start  ******************************************************* -->
        <v-dialog v-model="fill_dialog" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    충전 하시겠습니까?
                </v-card-title>
                <v-card-text>충전 금액이 필요합니다.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="fill_dialog = false">
                        취소
                    </v-btn>
                    <v-btn color="#5EB4F9" text @click="fillMoney">
                        확인
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="buy_dialog" persistent max-width="290">
            <v-card class="text-center">
                <v-card-title class="justify-center text-h5">
                    구매 하시겠습니까?
                </v-card-title>
                <v-card-text> 해당 상품을 구매하시겠습니까?</v-card-text>
                <v-card-text>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" class="white--text mr-3" @click="buy_dialog = false" style="min-width:100px;" rounded>
                        취소
                    </v-btn>
                    <v-btn color="primary" @click="payment" style="min-width:100px;" rounded>
                        확인
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="result_dialog" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    {{ result_data.title }}
                </v-card-title>
                <v-card-text> {{ result_data.content }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#5EB4F9" text @click="result_dialog = false">
                        확인
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="login_dialog" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    로그인이 필요합니다.
                </v-card-title>
                <v-card-text> 로그인 하시겠습니까?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="login_dialog = false">
                        취소
                    </v-btn>
                    <v-btn color="#5EB4F9" text @click="goLogin"> 확인 </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="confirm_dialog" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    구매완료하였습니다.
                </v-card-title>
                <v-card-text> 시작화면으로 돌아갑니다.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="#5EB4F9" text @click="goBack"> 확인 </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- *************************************************************************** -->
        <!-- Dialog : Get Server Start  ******************************************************* -->
        <v-dialog v-model="reuse_dialog" max-width="290">
            <v-card>
                <v-card-title class="text-h5"> 안내 </v-card-title>

                <v-card-text> {{ reuse_text }} </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="green darken-1"
                        text
                        @click="reuse_dialog = false"
                    >
                        확인
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar">
            {{ snackbar_msg }}

            <template v-slot:action="{ attrs }">
                <v-btn color="blue" text v-bind="attrs" @click="closePage">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <v-overlay :value="loading_bar">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
import axios from "../../../service/axios";

export default {
    name: "GiftDetailModal",

    components: {
        // OtcFooter: () => import("@/components/OtcFooter"),
    },

    data() {
        return {
            id: null,
            favorite: null,
            favorite_id: null,
            gift_data: null,
            update_yn: false,
            cash: null,
            fill_dialog: false,
            buy_dialog: false,
            result_dialog: false,
            result_data: {
                title: null,
                content: null,
            },
            deposit_type: false,
            loading_bar: false,
            snackbar: false,
            snackbar_msg: null,
            login_dialog: false,
            confirm_dialog: false,
            reuse_dialog: false,
            reuse_text: '',
            history: [
                {
                    id: "01",
                    event: "Minted",
                    who: "mothcar",
                    date: "2021.12.01",
                },
                {
                    id: "02",
                    event: "sell",
                    who: "mothcar",
                    date: "2021.12.01",
                },
                {
                    id: "03",
                    event: "sell",
                    who: "mothcar",
                    date: "2021.12.01",
                },
            ],
            test: '',
        };
    },

    props: {
        data: {
            type: Object,
            require: false,
        },
    },

    watch: {
        // item(){
        //     this.depositType();
        //     this.change_data();
        //     this.giftDetailInfo();
        // },
    },

    created() {
        window.scrollTo(0, 0);
        let article = this.$store.getters.getProduct;
        console.log("this. article ......... : ", article);
        let raw = article;
        let line = raw.article_description;
        let interpreter =''
        if(typeof line !== 'undefined') interpreter = line.replace(/(?:\r\n|\r|\n)/g, "<br />");
        
        article.article_description = interpreter;

        // if(article.article_code!=='') article.product_name = article.article_code

        this.gift_data = article;
        // this.history = product.history;
        // this.setPage(this.item)
        // this.depositType();
        // this.change_data();
        // this.giftDetailInfo();
    },

    methods: {
        initgiftData() {
            this.id = null;
            this.favorite = null;
            this.favorite_id = null;
            this.gift_data = {
                brand_id: null,
                brand_img: null,
                brand_name: null,
                category_id: null,
                category_name: null,
                create_date: null,
                create_time: null,
                favorite: null,
                favorite_id: null,
                org_price: null,
                article_code: null,
                product_img: null,
                article_name: null,
                sale_price: null,
                _id: null,
                expire_yn: false,
            };
        },

        change_data() {
            this.update_yn = false;
            if (this.$props.item != null) {
                this.id = this.$props.item._id;
                this.favorite = this.$props.item.favorite;
                this.favorite_id = this.$props.item.favorite_id;
            }
        },

        formatPrice(value) {
            try {
                return (
                    value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                    "원"
                );
            } catch {
                return value + "원";
            }
        },

        updateFavorite() {
            if (this.$cookies.get("token") != null) {
                const fav = this.favorite;
                this.favorite = !this.favorite;
                if (fav == true) {
                    this.delFavorite();
                } else {
                    this.addFavorite();
                }
            } else {
                this.snackbar_msg =
                    "로그인 전에는 관심 기프티콘을 등록하실 수 없습니다.";
                this.snackbar = true;
            }
        },

        // addFavorite() {
        //     const params = {
        //         accessKey: this.$cookies.get("token"),
        //         options: this.$props.item,
        //     };
        //     axios
        //         .post("/api/product/favoriteCreate", params)
        //         .then((res) => {
        //             console.log(res.data.data);
        //             this.update_yn = true;
        //         })
        //         .catch((error) => {
        //             console.log("페스트 오류", error);
        //         });
        // },
        // delFavorite() {
        //     const params = {
        //         accessKey: this.$cookies.get("token"),
        //         options: this.$props.item.favorite_id,
        //     };
        //     axios
        //         .post("/api/product/favoriteDelete", params)
        //         .then((res) => {
        //             console.log(res.data.data);
        //             this.update_yn = true;
        //             this.$emit("delete-event");
        //         })
        //         .catch((error) => {
        //             console.log("페스트 오류", error);
        //         });
        // },

        // async trackingQnt(item) {
        //     console.log('item : ', item)
        //     // if (this.$cookies.get("token") == null) {
        //     //     this.reuse_text = '로그인이 필요합니다'
        //     //     this.reuse_dialog = true;
        //     //     return
        //     // }
        //     // getArticleQnt
        //     let options = {
        //         article_code: item.article_code
        //     }
        //     const raw = await axios.get("/article/getArticleQnt", {params:options});
        //     if (raw) this.loading_bar = false;
        //     console.log("Quantity from Raw .... ", raw.data.data.item.qnt);
        //     let qnt = raw.data.data.item.qnt 
        //     if(qnt>0){
        //         console.log("Quantity ", qnt);
                
        //         this.reuse_text = '재고 있음'
        //         console.log('payment를 활성화해주세요')
        //         // this.payment(item)
        //         return null
        //     }
        //     this.reuse_text = '재고가 없습니다'
        //     this.reuse_dialog = true;
        // },

        async buyAdapter() {
            // console.log("access token", this.$cookies.get("token"));
            console.log('Gift Record : ', this.gift_data)

            if (this.$cookies.get("token") != null) {
                this.loading_bar = true;
                // await this.expireToken();
                // await this.walletInfo();
                // console.log('get Cash : ', this.$store.getters.getBalance)
                this.cash = this.$store.getters.getBalance;
                if (this.cash > this.gift_data.sale_price) {
                    this.loading_bar = false;
                    // this.buy_dialog = true;

                    let options = {}
                    if(this.gift_data.article_code !== ''){
                        options = {
                            article_code: this.gift_data.article_code
                        }
                        const raw = await axios.get("/article/getArticleQnt", {params:options});
                        if (raw) this.loading_bar = false;
                        console.log("Quantity from Raw .... ", raw.data.data.item.qnt);
                        let qnt = raw.data.data.item.qnt 
                        if(qnt>0){
                            console.log("Quantity ", qnt);
                            // this.reuse_text = '재고 있음'
                            // this.payment()
                            this.buy_dialog = true;
                            return null
                        }
                    } else {
                        this.buy_dialog = true;
                        return null
                    }

                    this.reuse_text = '죄송합니다. 재고 준비중입니다.'
                    this.reuse_dialog = true;

                } else {
                    this.loading_bar = false;
                    this.fill_dialog = true;
                }
            } else {
                this.result_data.title = "로그인 후 이용해주세요.";
                this.result_data.content = "로그인 후 이용하실 수 있습니다.";
                this.result_dialog = true;
            }
        },

        async payment() {
            this.buy_dialog = false;
            // console.log('payment를 활성화해주세요')

            this.loading_bar = true;
            const token = this.$cookies.get("token");
            const params = {
                accessKey: token,
                buyItem: this.gift_data
            };
            console.log("Payment params .... Done? ", params);
            const raw = await axios.post("/payment/buyGifticon", params);
            if (raw) {
                this.confirm_dialog = true;
                this.loading_bar = false;
            }
            // console.log("from buyGifticon-Server : ", raw.data.data.item);
            
            const userBalance = raw.data.data.item;
            console.log("from buyGifticon-Server : ", userBalance);
            this.$store.commit({ type: "setBalance", balance: userBalance });
            this.cash = userBalance;

            // this.$router.go(-1);
            this.$router.replace('/')
        },

        goBack() {
            this.$router.go(-1);
        },

        fillMoney() {
            this.$router.push("/payments/AddMoney");
            // this.fill_dialog = false;
            // const payment_info = {
            //     deposit_ammount: 11000,
            //     deposit_type: '입금'
            // };
            // const params = {
            //     accessKey: this.$cookies.get('token'),
            //     options: payment_info
            // }
            // axios.post('/api/payment/createDepositCharge', params).then(res =>{
            //     this.result_data.title = res.data.data.title;
            //     this.result_data.content = res.data.data.content;
            //     this.result_dialog = true;
            // }).catch(error=>{
            //     console.log('페스트 오류', error)
            // });
        },
        closePage() {
            this.snackbar = false;
        },

        async depositType() {
            let accessKey = this.$cookies.get("token");
            if (accessKey != null) {
                this.loading_bar = true;
                let options = {
                    deposit_type: "출금신청",
                };
                const params = {
                    accessKey: this.$cookies.get("token"),
                    options: options,
                };
                console.log(params);

                await axios
                    .post("/api/payment/depositTypeInfo", params)
                    .then((res) => {
                        let data = res.data.data;
                        if (data != null) {
                            this.deposit_type = true;
                        } else {
                            this.deposit_type = false;
                        }
                    })
                    .catch((error) => {
                        this.loading_bar = false;
                        console.log("페스트 오류", error);
                    });
            }
        },
        async expireToken() {
            const params = {
                accessKey: this.$cookies.get("token"),
            };
            await axios
                .post("/api/auth/tokenInfo", params)
                .then((res) => {
                    this.expire_yn = res.data._yn;
                })
                .catch((error) => {
                    this.loading_bar = false;
                    console.log("페스트 오류", error);
                });
        },
        goLogin() {
            this.login_dialog = false;
            this.$cookies.remove("token");
            this.$router.push("/sign/login");
        },
    },
};
</script>

<style lang="scss">
.detail_info {
    font-size: 0.85rem;
    letter-spacing: -0.03em;
    line-height: 1.4;
}
</style>

<style lang="scss" scoped>
.v-toolbar__title {
    font-size: 1rem !important;
    color: #ffffff;
}
</style>
